/* reset CSS start */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  font-family: "Montserrat", "segoe ui", "Helvetica", Arial, sans-serif;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* reset CSS end */

.lock-scroll {
  overflow: hidden;
}

.headerContainer {
  /* position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center; */
}
.header-text {
  position: relative;
}
.header-logo {
  position: relative;
}

.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}

.font-size-xsmall {
  font-size: 12px;
}
.font-size-small {
  font-size: 16px;
}
.font-size-medium {
  font-size: 24px;
}
.font-size-large {
  font-size: 32px;
}
.font-size-xlarge {
  font-size: 40px;
}
.width-small {
  width: 100px;
}
.height-small {
  height: 100px;
}
.width-medium {
  width: 200px;
}
.height-medium {
  height: 200px;
}
.width-large {
  width: 300px;
}
.height-large {
  height: 300px;
}

.underline {
  text-decoration: underline;
}

/*Added spinner tick animation start*/
#AddedTick {
  stroke: #63bc01;
  stroke-width: 6;
  transition: all 0.3s;
}

#AddedCircle {
  stroke: #63bc01;
  stroke-width: 6;
  transform-origin: 50px 50px 0;
  transition: all 0.3s;
}

.progress #AddedTick {
  opacity: 0;
}

.ready #AddedTick {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 1s ease-out forwards;
}

.progress #AddedCircle {
  stroke: #4c4c4c;
  stroke-dasharray: 314;
  stroke-dashoffset: 1000;
  animation: spin 1.7s linear infinite;
}

.ready #AddedCircle {
  stroke-dashoffset: 66;
  stroke: #63bc01;
}

#AddedCircle {
  stroke-dasharray: 500;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    stroke-dashoffset: 66;
  }
  50% {
    transform: rotate(540deg);
    stroke-dashoffset: 314;
  }
  100% {
    transform: rotate(1080deg);
    stroke-dashoffset: 66;
  }
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

#check {
  width: 25px;
  height: 25px;
}
/*Added spinner tick animation end*/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
